// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-cards-default-js": () => import("../src/cards/default.js" /* webpackChunkName: "component---src-cards-default-js" */),
  "component---src-pages-game-over-js": () => import("../src/pages/game-over.js" /* webpackChunkName: "component---src-pages-game-over-js" */),
  "component---src-pages-get-js": () => import("../src/pages/get.js" /* webpackChunkName: "component---src-pages-get-js" */),
  "component---src-pages-howto-js": () => import("../src/pages/howto.js" /* webpackChunkName: "component---src-pages-howto-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

